<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float:left">
        <el-button v-if="auth['followrecord:add']" type="primary" icon="el-icon-plus" @click="handle_add()">添加</el-button>
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
      <div style="float:right;">
        <el-input placeholder="跟进人" v-model="uname" class="input-with-select" style="width:250px;"></el-input>&nbsp;
        <el-button type="primary" @click="handler_search()" icon="el-icon-search">搜索</el-button>
      </div>
    </div>

    <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
<!--      <el-table-column prop="planid" label="跟进计划" width="300"></el-table-column>-->
      <el-table-column prop="customer.name" label="客户" width="200"></el-table-column>
      <el-table-column prop="uid" label="跟进人" width="170">
        <template  #default="scope">
          <el-row>
            姓名：{{scope.row?.user?.name}}
          </el-row>
          <el-row>
            电话：{{scope.row?.user?.mobile}}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="target" label="跟进目标" width="300">
        <template #default="scope">
          <el-input type="textarea" :rows="2" :disabled="true" placeholder="跟进目标" v-model="scope.row.target"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="result" label="跟进结果" width="300">
        <template #default="scope">
          <el-input type="textarea" :rows="2" :disabled="true" placeholder="跟进结果" v-model="scope.row.result"></el-input>
        </template>
      </el-table-column>
<!--      <el-table-column prop="status" label="状态" width="50"></el-table-column>-->
      <el-table-column prop="scope" label="操作" min-width="100">
        <template #default="scope">
          <el-button v-if="auth['followrecord:edit']" type="text" icon="el-icon-edit" @click="handle_follow(scope.row)">修改</el-button>
          <el-button v-if="auth['followrecord:del']" type="text" icon="el-icon-delete" class="red" @click="handle_del(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
                   :current-page="page.pageNum"
                   :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                   :total="page.total">
    </el-pagination>
  </div>
</template>

<script>
import FOLLOWREC from "../../api/biz/api_followrecord";
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import PM from "@/api/base/api_pm";

export default {
  name: "followrecordlist", // 跟进记录
  props: {
    customerInfo: {
      type: Object
    },
    uname: {
      type: String
    }
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      auth: {}
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.auth=this.$getAuth('/followList');
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      let customerId = null;
      if (this.customerInfo != null) {
        customerId = this.customerInfo.id;
      }

      FOLLOWREC.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        custid: customerId,
        uname: this.uname
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    // 删除
    handle_del(index, row) {
      this.$confirm('确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        FOLLOWREC.delete({id: row.id}).then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功！");
            this.initPageData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    },

    handle_add(row) {
      let {...clonerow} = row;
      this.$router.push({
        path: '../followRecordAdd',
        query: {
          customerId: this.customerInfo.id
        }
      });
    },

    //follow
    handle_follow(row) {
      let {...clonerow} = row;
      this.$router.push({
        path: '../followRecordEdit',
        query: {
          customerId: row.custid,
          followRecordId: row.id
        }
      });
    },

    handler_search() {
      this.initPageData();
    },

  }
}
</script>

<style scoped>
.pageHeader {
  background-color: white;
  border-bottom: 1px solid #CCCCCC;
  font-size: 18px;
  padding-bottom: 5px;
  padding-top: 5px;
  /*padding: 5px 10px;*/
}

.red {
  color: #ff0000;
}
</style>